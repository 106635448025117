// React Imports
import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

// Image Imports
import logo from "../images/logo_mirati-foot.svg";
import logoBMS from "../images/logo-bms-foot.svg";
import privacyOpts from "../images/privacyoptions.svg";

export default function Footer(props) {
  const [siteSection, setSiteSection] = useState("");
  const [footerNav, setFooterNav] = useState([
    {
      title: "References",
      internal: true,
      url: "/c/references",
      className: "references",
    },
    {
      title: "Legal Notice",
      internal: false,
      url: "https://www.bms.com/legal-notice.html",
    },
    {
      title: "Privacy Policy",
      internal: false,
      url: "https://www.bms.com/privacy-policy.html",
    },
    {
      title: "Your Privacy Choices",
      oneTrust: true,
      url: "/",
    },
  ]);

  const location = useLocation();

  // Update the footer refs link.
  useEffect(() => {
    const currentSection = window.location.pathname.split("/")[1];
    const temp = footerNav.find((obj) => obj.title === "References");
    temp.url = `/${currentSection}/references`;
    footerContent();
  }, [location, siteSection]);

  function footerContent() {
    if (document.body.classList.contains("nsclc")) {
      setSiteSection("nsclc");
    } else if (document.body.classList.contains("crc")) {
      setSiteSection("crc");
    } else {
      setSiteSection("landing");
    }
  }

  return (
    <footer className="bms-footer">
      <div className="content-wrapper">
        <div className="col">
          <a href="https://www.bms.com/" target="_blank" rel="noreferrer" className="bms-link">
            <img src={logoBMS} alt="Bristol Myers Squibb Company Logo" title="Bristol Myers Squibb" />
          </a>

          <p>
            &copy; 2024 Mirati Therapeutics, Inc.,
            <br className="mobile-only"></br> a Bristol Myers Squibb company.
          </p>

          <p className="mw-380">
            Access Support is a trademark of Bristol-Myers Squibb Company. KRAZATI and the related logo are trademarks
            of Mirati Therapeutics, Inc.
          </p>

          <p>
            <span className="footer-code landing">US-KRA-24-00054 v1 6/24</span>
            <span className="footer-code nsclc">1914-US-2400475 09/24</span>
            <span className="footer-code crc">US-KRA-24-00100 v1 9/24</span>
          </p>
        </div>

        <div className="col flex-container">
          <div className="footer-nav">
            {footerNav.map(({ title, internal, url, oneTrust, className }) => {
              if (internal) {
                return (
                  <React.Fragment key={title}>
                    <Link to={url} className={className}>
                      {title}
                    </Link>
                    <div className="divider"></div>
                  </React.Fragment>
                );
              }
              if (oneTrust) {
                return (
                  <React.Fragment key={title}>
                    <a
                      href={url}
                      rel="noreferrer"
                      className="one-trust"
                      onClick={(e) => {
                        e.preventDefault();
                        window.OneTrust.ToggleInfoDisplay();
                      }}>
                      <div>
                        {title} <img src={privacyOpts} />
                      </div>
                    </a>
                    <div className="divider"></div>
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={title}>
                  <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className={className}
                    onClick={(e) => {
                      e.preventDefault();
                      props.openModal(url);
                    }}>
                    {title}
                  </a>
                  <div className="divider"></div>
                </React.Fragment>
              );
            })}
          </div>
          <p>
            <em>This website is intended for U.S. residents 18 years of age or older.</em>
          </p>
        </div>
      </div>
    </footer>
  );
}
